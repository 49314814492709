import ng from 'angular';
import { acpCardLandingModule } from 'components/card-landing';
import acpCore from 'core';
import acpPaywallOrderPersoCardComponent from './acp-paywall-order-perso-card-component';
import acpPaywallOrderPersoCardContent from './acp-paywall-order-perso-card-content.yml';
import './styles/${theme}/core.scss';

export default ng
  .module('acp.component.acp-paywall-order-perso-card', [
    acpCore.name,
    acpCardLandingModule.name,
    'netspend.legos.progressbar'
  ])
  .component('acpPaywallOrderPersoCard', acpPaywallOrderPersoCardComponent)
  .run((contentSectionCache) => {
    'ngInject';
    contentSectionCache.put(
      'components/acp-paywall-order-perso-card',
      acpPaywallOrderPersoCardContent
    );
  });
