import {
  AcpOrderCardModel,
  AcpProgressBarModel,
  OrderCardStep
} from 'components/card-domain';
import './styles/${theme}/core.scss';
import acpPaywallOrderPersoCardTemplate from './templates/acp-paywall-order-perso-card.html';

export class AcpPaywallOrderPersoCardComponent
  implements nsUtils.NsComponentController {
  private steps: OrderCardStep[];
  private flowTitle: string;
  private defer: any;

  constructor(
    nsComponentDecorator,
    private nsInPageFlow: any,
    private content: any,
    private acpOrderCardModel: AcpOrderCardModel,
    private acpProgressBarModel: AcpProgressBarModel,
    private acpCoreDispatcher: any
  ) {
    'ngInject';
    nsComponentDecorator(this, this);
  }

  public async $onInit() {
    this.acpProgressBarModel.getSteps().then((steps) => (this.steps = steps));
    this.handleListeners();
    this.content
      .getKey(
        'order-personalized-card',
        'components/acp-paywall-order-perso-card'
      )
      .then((data) => (this.flowTitle = data.copy));
  }

  public orderPersoCard(): void {
    this.acpOrderCardModel.reset();
    this.nsInPageFlow.open({
      flowTitle: this.flowTitle,
      template: this.steps[this.acpProgressBarModel.getCurrentStep()].component,
      onBackFunction: () => this.acpProgressBarModel.back()
    });
  }

  public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  private handleListeners() {
    this.$onValue(this.acpCoreDispatcher.card.cardsUpdate, () => {
      this.defer.resolve();
    });
  }
}

const acpPaywallOrderPersoCardComponent: ng.IComponentOptions = {
  controller: AcpPaywallOrderPersoCardComponent,
  controllerAs: 'vm',
  template: acpPaywallOrderPersoCardTemplate,
  bindings: {
    defer: '<'
  }
};

export default acpPaywallOrderPersoCardComponent;
